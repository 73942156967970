<template>
  <b-card>
    <b-card-text>
      <h3>Spis kodów rabatowych: <a href="https://tomaszkrawczyk.pl/kody-rabatowe-chemmaster-2021-2022" target="_blank">Kliknij tutaj</a></h3>
      <p>Kody rabatowe nie sumują się.</p>
      <b-list-group>
        <b-list-group-item
          v-for="item in discounts"
          :key="item"
          class="flex-column align-items-start"
        >
          <div class="row mb-1 mt-2">
            <div class="col-sm-6 mb-1 mb-sm-0">{{ item.name }}</div>
            <div class="col-sm-2 mb-1 mb-sm-0 text-sm-center">wartość: <strong>{{ item.discount_value }}%</strong> </div>
            <div class="col-sm-2 mb-1 mb-sm-0 text-sm-center">kod: <strong>{{ item.code }}</strong></div>
            <div class="col-sm-2 mb-1 mb-sm-0 text-sm-right">ważność: <strong>{{ item.expired_at}}</strong></div>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-card-text>
    <h3 class="text-center mt-5 mb-5" v-if="discounts.length === 0">
      Nie masz przypisanych kodów rabatowych
    </h3>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { useShopFiltersSortingAndPagination } from '@/views/shop/useECommerceShop'
import { useEcommerceUi } from '@/views/shop/useEcommerce'

export default {
  components: {
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      discounts: [],
    }
  },
  created() {
    this.fetchDiscounts()
  },
  methods: {
    fetchDiscounts() {
      this.$http.get(`https://api.${this.$store.state.domain}/api/my/discounts`).then(response => {
        this.discounts = response.data.discounts
      })
    },
  },
  setup() {
    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    return {
      toggleProductInWishlist,
      handleCartActionClick,
    }
  },
}
</script>

<style>

</style>
